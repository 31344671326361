<template>
    <page-layout linkDefault="/" title="Discount Card" logo :backButton="true">

        <div class="md:mr-8 ml-8 flex flex-col lg:flex-row items-center justify-center sm:px-6 sm:pb-32 lg:grid lg:grid-cols-2">
                <img :src="require('@/assets/Card.png')" alt="RuuPlus Card" class="w-80 md:mx-auto -ml-2 rounded-2xl" />

                <div class="mx-auto max-w-2xl lg:grid lg:max-w-7xl pt-12 md:pt-16 lg:pt-32">
                    <div class="mb-20 lg:mb-0 mr-12">
                        <h2 id="features-heading" class="font-medium text-gray-500">RuuPlus</h2>
                        <p class="mt-4 text-4xl font-bold tracking-tight text-gray-900">A Discount Card Designed Specifically For You</p>
                        <p class="mt-4 text-gray-500 whitespace-pre-line">{{`Save up to thousands of pounds with our exclusive Ruu Plus card! 
                                Do you want to get the most out of your student experience? The Ruu+ discount card grants you access to unique offers at a variety of vendors: from fitness and mental health, to clothes and coffee! With incredible discounts, Ruu+ will make you enjoy your favorite products and services without having to worry about the price. Get your Ruu+ card and start enjoying your best student life now!
                                `}}</p>

                        <dl class="mt-10 grid grid-cols-1 gap-x-8 gap-y-4 text-sm sm:grid-cols-2">
                            <div v-for="feature in features" :key="feature">
                            <dt class="font-medium text-gray-900">{{ feature }}</dt>
                            </div>
                        </dl>
                    </div>
                    
                    <div class="fixed inset-x-0 bottom-0 m-4 md:relative md:m-0">
                        <Button color="primary" shape="full" @click="$event=>this.$router.push('/pickupLocation')" class="m-10 mb-0 mx-auto lg:mx-0 lg:m-6">Purchase Card</Button>
                    </div>
                </div>
        </div>

    </page-layout>
</template>

 
<script>
import PageLayout from "../components/base/pageLayout.vue";
import Button from "../components/Feed/Button.vue"
export default {
    components: { PageLayout, Button },
    data() {
        return {
            features: ["Exclusive", "Easy To Use", "Convenient", "Thoughtfully Designed"]}
    }
}
</script>
 
<style lang="scss" scoped></style>